import React from 'react';
import Helmet from 'react-helmet';
import { Link } from 'react-router-dom';
import { Image, Transformation, CloudinaryContext } from 'cloudinary-react';
import cloudinary from 'cloudinary-core';
import ContactModel from '../components/ContactModel';
import gooseLogo from './../assets/icon/goose-logo.svg';
import { graphql } from 'gatsby';
import Layout from '../components/Layout/layout';

const cloudinaryCore = new cloudinary.Cloudinary({ cloud_name: 'wearegoosebclandingpage', secure: true });

const LogoGoose = () => (
  <svg viewBox={gooseLogo.viewBox}>
    <use xlinkHref={gooseLogo.url} />
  </svg>
);

class HiringRoute extends React.Component {
  state = { contactModelActive: false };

    handleContactModelClick = () => {
      this.setState({ contactModelActive: !this.state.contactModelActive });
    };


    render() {
      const {
        contactEmail,
        title,
        subtitle,
        contactNumber,
        keywords,
        socialLinks
      } = this.props.data.site.siteMetadata;
      return (
        <Layout>
        <section className="c-homeServices l-homeServices">
          <CloudinaryContext cloudName="wearegoosebclandingpage" fetchFormat="auto" secure="true" width="auto" quality="auto" dpr="auto">
            <div className="vc-bg-lines dark"><div /><div /><div /><div /><div /><div /><div /></div>


            <div className="c-jobDescription">
              <div className="u-container u-container--m"><br /><br /><br />
                <div className="l-bigCommerceBenefits__badge">
                  <LogoGoose />
                </div>
                <div className="c-jobDescription__heading l-jobDescription__heading u-container u-container--xs">
                  <h3 className="l-jobDescription__heading__header u-h1">We're hiring</h3>
                  <p className="u-intro">We are on the hunt for a new wingman. <br />
                  We need a talented Frontend Developer. Who can interpret customer requirements, provide solutions and get things done. Someone who genuinely loves what they do.
                  </p> <br />
                  <p className="o-tgQuote">"It takes a lot more than just fancy flying." - Charlie</p>
                </div>
                <div className="c-jobDescription__main u-container u-container--xxs">
                  <p>An ideal candidate is a person who is happy to get into the weeds of a project, likes to fine-tune their work and gets satisfaction from delivering work they are proud of.</p>

                  <p>You will be working directly alongside the founders of Goose. We work with eCommerce businesses. We have clients that are looking to grow their online business. Our clients run BigCommerce, Shopify and Magento stores.</p>
                  <br />
                  <h3>Your skills should include:</h3>
                  <ul>
                    <li>Minimum of 3 years commercial experience</li>
                    <li>Extensive experience on BigCommerce, Shopify or Magento</li>
                    <li>Advanced Vanilla JS knowledge with React or Vue experience</li>
                    <li>Strong semantic HTML and CSS skills</li>
                    <li>Git Source Control</li>
                    <li>Performance focused development</li>
                    <li>Learning new things is a priority</li>
                  </ul>

                  <h3>Bonus points:</h3>
                  <ul>
                    <li>In-depth BigCommerce knowledge</li>
                    <li>You are a seasoned Freelancer/Contractor and have the scars to prove it</li>
                    <li>Can adapt to new platforms</li>
                    <li>Do you have node chops too???</li>
                  </ul>

                  <hr />

                  <ul>
                    <li>Working remotely is fine by us</li>
                    <li>Do you feel like you need to learn more? Yep, we do too. So we just plan time into our week to learn new stuff</li>
                    <li>Work better at certain times of the day. That’s funny, we all start and finish at different times too</li>
                  </ul>
                  <p className="u-note">Note: Please don't send us your CV or Cover Letter. We will invite you to send these.</p>

                </div>
                <div className="c-apply">
                  <div className="c-applyBox l-applyBox">
                    <div className="c-applyBox__content">
                      <h4 className="c-applyBox__header u-h3">To Apply</h4>
                      <p className="c-applyBox__text">If you’re interested in this job, <a href="mailto:info@wearegoose.co.nz">Get in touch.</a> </p>
                      <p>Tell us about yourself. What is your superpower? Tell us something specific about a recent project you have worked on. If we think you might be a good fit, we’ll reach out to you!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ContactModel toggleContactModel={this.handleContactModelClick} siteMeta={this.props.data.site.siteMetadata} ContactModelStatus={this.state.contactModelActive} />

          </CloudinaryContext>
        </section>
        </Layout>
      );
    }
}

export default HiringRoute;

export const pageQuery = graphql`
  query HiringRouteQuery {
    site {
      siteMetadata {
        title
        subtitle
        copyright
        contactEmail
        menu {
          label
          path
        }
        author {
          name
          email
          telegram
          twitter
          github
          rss
          vk
        }
      }
    }
  }
`;
